<template>
  <Form />
</template>

<script>
export default {
    components:{
        Form:()=>import('./components/Form.vue')
    }
}
</script>

<style>

</style>